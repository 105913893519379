import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import UniversalCard from '../components/molecules/UniversalCard';
import CFS from '../components/molecules/CFS';
import Checkmark from '../components/atoms/Checkmark';

import imgSecSection from '../assets/images/pages/la-historia/thirdSecImg.png';
import imgGr from '../assets/images/pages/la-historia/imgF.svg';
import imgEpeea from '../assets/images/pages/la-historia/imgS.svg';
import imgAm from '../assets/images/pages/la-historia/imgT.svg';
import imgCTA from '../assets/images/pages/la-historia/rightImg.svg';

import '../assets/styles/pages/la-historia.scss';

const LaHistoriaDeEleBarcelona = () => (
  <Layout
    title="La Historia de ELE Barcelona | ¿Cómo empezamos?"
    description="Descubre la historia de ELE Barcelona. Cómo empezamos, quiénes somos, qué hacemos y cómo lo hacemos"
  >
    <div className="la-historia">
      <CFS title="La historia de ELE Barcelona" />
      <section className="second-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="left">
                <h3 className="left__title c-title-34">¿Cómo empezamos?</h3>
                <p className="left__description c-text-16">
                  <span className="c-text-16 c-text-16--gray-100">
                    María llegó desde Sevilla en búsqueda de aventuras y nuevas experiencias.
                    Primero trabajó como profesora de español en diferentes escuelas y empresas en
                    su ciudad natal hasta que un día decidió cambiar de aires y se fue al sudeste
                    asiático a dar clases de español.
                    <br />
                    <br /> Concretamente trabajó dando clases en Camboya y China durante 4 intensos
                    años. Al regresar a España volvía con la ilusión de poner en práctica todos los
                    conocimientos docentes adquiridos y con la madurez de la experiencia de haber
                    vivido en el extranjero. Fue entonces cuando decidió instalarse en Barcelona
                    para emprender un nuevo proyecto profesional: montar una escuela de formación de
                    profesores de español. Y así fue como se creó nuestra escuela, como un espacio
                    docente con clases cómodas y modernas, pizarras digitales, una preciosa
                    biblioteca y una galería modernista de ensueño. En este contexto profesional
                    nació <span class="text-fw-600">ELE Barcelona</span>, de la fusión de
                    experiencias de profesores que vivimos al máximo esta profesión y deseamos
                    compartirla con los demás.
                  </span>
                  <br />
                  <br />
                  El objetivo es ayudarte a seguir el mismo camino que nosotros y que encuentres un
                  trabajo que te motive y además te permita vivir como quieras. Todo el mundo en
                  nuestra escuela ha hecho el mismo viaje que tú así que ¡estás en buena compañía!
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img className="cta-img" src={imgCTA} alt="imgCTA" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5">
              <h2 className="c-title-34 mb-5">¿Qué hacemos?</h2>
              <p className="c-text-18">
                Te ayudamos a cambiar de profesión y a encontrar un trabajo que realmente te motive.
              </p>
            </div>
          </div>
          <div className="row extra-info-cards">
            <div className="col-lg-4 col-sm-6">
              <UniversalCard
                title="Formación docente"
                description="Recibirás una formación de primera calidad para darte una base sólida en tu nueva carrera profesional."
                img={imgGr}
              />
            </div>
            <div className="col-lg-4 col-sm-6">
              <UniversalCard
                title="Orientación laboral"
                description="Encontrarás el trabajo idóneo para ti gracias a nuestro experto asesoramiento y el acceso a la red de contactos locales e internacionales."
                img={imgEpeea}
              />
            </div>
            <div className="col-lg-4 col-sm-6">
              <UniversalCard
                title="Un apoyo integral"
                description="Te damos todo lo que necesitas para empezar: materiales de clase, ayuda en las planificaciones, asesoramiento continuo, apoyo en la observación de otros profesores, formación continua post curso ¡y mucho más!"
                img={imgAm}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="third-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="left">
                <img src={imgSecSection} alt="Happy person" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="right">
                <h3 className="right__title c-title-34">¿Cómo lo hacemos?</h3>
                <p className="right__description c-text-15 c-text-15--gray-100">
                  Hemos formado a decenas de nuevos profesores y todos tienen cosas fantásticas que
                  decir sobre la escuela. Definitivamente mencionan:
                </p>
                <div className="wrapper">
                  <Checkmark>Los grupos formativos pequeños (8 máximo, no 20).</Checkmark>
                  <Checkmark>
                    La enseñanza práctica con estudiantes reales de distintas nacionalidades, grupos
                    grandes y pequeños, niveles de principiante a avanzado, clases dentro y fuera
                    del aula.
                  </Checkmark>
                  <Checkmark>El excelente apoyo de nuestro personal.</Checkmark>
                  <Checkmark>Haberles inculcado la pasión para enseñar.</Checkmark>
                  <Checkmark>
                    Las oportunidades laborales y formativas que tienen a su disposición después de
                    la formación (trabajamos muy duro para hacer de ELE Barcelona una de las marcas
                    de ELE más reconocidas).
                  </Checkmark>
                  <Checkmark>La titulación reconocida nacional e internacionalmente.</Checkmark>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default LaHistoriaDeEleBarcelona;
